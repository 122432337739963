.hover-border:hover {
  box-shadow: 0px 0px 0px 2px var(--main);
  border: 1px solid var(--main);
  transition: all 0.3s ease;
}

.hover-border-moon-gray:hover {
  border: 1px solid var(--moon-gray);
  transition: all 0.3s ease;
}

.hover-bg-teal:hover {
  background-color: var(--main);
  transition: all 0.3s ease;
}

.hover-bg-light-gray:hover {
  background-color: var(--light-gray) !important;
}

.hover-teal:hover,
.hover-teal:focus {
  color: #015b5b;
  transition: all 0.3s ease;
}

.grow {
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}

.grow:hover,
.grow:focus {
  transform: scale(1.02);
}

.grow:active {
  transform: scale(0.98);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.animate-background {
  transition: background-color 1s ease;
}

.tealbotHelp-enter {
  opacity: 0.01;
}
.tealbotHelp-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}
.tealbotHelp-exit {
  opacity: 1;
}
.tealbotHelp-exit-active {
  opacity: 0.01;
  transform: scale(0.1, 0.1);
  transition: all 500ms ease-in;
}

.parallax {
  background-attachment: local;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: all 500ms ease-in;
}

.loading {
  position: relative;
  overflow: hidden;
}
.loading::after {
  content: '';
  display: block;
  background-color: #dddfe2;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  animation: 1.5s loading-placeholder ease-in-out infinite;
}

@keyframes loading-placeholder {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.hover-swing-child:hover > .swing {
  animation: 1.5s swing ease-in-out infinite;
}

.hover-swing:hover {
  animation: 1.5s swing ease-in-out infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.char1 {
  transform: rotate(-65deg);
  left: 65px;
  top: 20px;
  position: absolute;
}
.char2 {
  transform: rotate(-55deg);
  left: 77px;
  top: 7px;
  position: absolute;
}
.char3 {
  transform: rotate(-45deg);
  left: 87px;
  top: -2px;
  position: absolute;
}
.char4 {
  transform: rotate(-35deg);
  left: 95px;
  top: -8px;
  position: absolute;
}
.char5 {
  transform: rotate(-25deg);
  left: 106px;
  top: -13px;
  position: absolute;
}

.char6 {
  transform: rotate(-10deg);
  left: 120px;
  top: -17px;
  position: absolute;
}
.char7 {
  transform: rotate(0deg);
  left: 133px;
  top: -18px;
  position: absolute;
}

.char9 {
  transform: rotate(25deg);
  left: 160px;
  top: -14px;
  position: absolute;
}
.char10 {
  transform: rotate(36deg);
  left: 173px;
  top: -9px;
  position: absolute;
}
.char11 {
  transform: rotate(44deg);
  left: 183px;
  top: -3px;
  position: absolute;
}
.char12 {
  transform: rotate(47deg);
  left: 190px;
  top: 4px;
  position: absolute;
}
.char13 {
  transform: rotate(59deg);
  left: 198px;
  top: 12px;
  position: absolute;
}
