body {
  background-color: var(--bg-tb-gray);
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: var(--main);
  text-decoration: none;
}

.scrollbar::-webkit-scrollbar {
  width: 0.5em;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 0.25em;
}

.rte-readonly-wrapper > div {
  font-family: 'Open Sans', sans-serif;
  border: none;
  color: #555;
}

.rte-readonly-wrapper .public-DraftEditor-content {
  padding: 0;
  font-size: 0.875rem;
}

.react-rte-editor .public-DraftEditor-content {
  min-height: 100px;
  font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 600px) {
  .react-rte-editor .public-DraftEditor-content {
    height: 150px;
  }
}

.react-rte-editor .public-DraftEditorPlaceholder-root {
  font-family: sans-serif;
}

.focus-outline:focus-visible {
  outline: auto black;
}
