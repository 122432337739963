input::-webkit-calendar-picker-indicator {
  display: none;
}

input::placeholder,
textarea::placeholder {
  opacity: 0.5;
}

input[type='search']::placeholder {
  opacity: 1;
}
textarea {
  resize: none;
}

.vet-text::placeholder {
  font-style: italic;
}

input:disabled,
select:disabled {
  background: #f0f0f0;
}

input.focus-ba:focus {
  border-style: solid;
  border-width: 1px;
}

input.focus-bg-white:focus {
  background: white;
}
